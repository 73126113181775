import { FARO_BUTTON_HEIGHT, FaroButton } from "@components/common/faro-button";
import { EDecimalToHex, sphereColors } from "@styles/common-colors";
import { addTransparency } from "@utils/ui-utils";
import { Box } from "@mui/material";
import { useCallback} from "react";
import { isElsScanFileUploadTaskContext } from "@custom-types/file-upload-type-guards";
import { useProjectApiClient } from "@api/project-api/use-project-api-client";
import { assert } from "@faro-lotv/foundation";
import { useCancelRevision } from "@hooks/data-management/use-cancel-revision";
import { useDialog } from "@components/common/dialog/dialog-provider";
import { Typography } from "@mui/material";
import { SPACE_ELEMENTS_OF_MODAL } from "@components/common/dialog/faro-dialog";
import { useUploadTasks } from "@hooks/upload-tasks/use-upload-tasks";
import { useFileUploadContext } from "@context-providers/file-upload/file-uploads-context";
import { useCancelUpload } from "@hooks/upload-tasks/use-cancel-upload";
import { useAppSelector } from "@store/store-helper";
import { selectedProjectSelector } from "@store/projects/projects-selector";
import { SphereTooltip } from "@components/common/sphere-tooltip";



/** Props for Cancel Import Button */
interface Props {
    /** Flag to disable the button */
    isDisabled: boolean;
}
  
/** Button to cancel upload of scans */
export function CancelImportButton({isDisabled}: Props): JSX.Element{
  const { createDialog } = useDialog();
  const { inProgressTasks} = useUploadTasks();
  const { uploadManager } = useFileUploadContext();
  const cancelUploadTask = useCancelUpload();
  const cancelRevision = useCancelRevision();

  const project = useAppSelector(selectedProjectSelector);
  // project should not be null
  assert(project, "Project has to be defined");
  
  const projectApiClient = useProjectApiClient({
    projectId: project.id,
  });

  /** Func to delete all ongoing upload tasks without asking for a prompt */ 
  const onCancelAllUploadTasksClick = useCallback(async (): Promise<void> => {
    // prompt the user to confirm abortion of uploads
    const hasAccepted = await createDialog(
      {
        title: "Cancel Import?",
        confirmText: "Cancel Import",
        closeText: "Resume Import",
      },
      <Typography
        sx={{
          fontSize: "14px",
          marginTop: "0px",
          marginBottom: SPACE_ELEMENTS_OF_MODAL,
          maxWidth: "400px",
        }}
      >
        You are about to interrupt the current import process.
        Any files that have been uploaded will be removed and you'll need to start over with a new upload.
      </Typography>
    );

    if (hasAccepted) {
      // get registration revision Id
      assert(isElsScanFileUploadTaskContext(inProgressTasks[0].context));
      const registrationRevisionId = inProgressTasks[0].context.registrationRevisionId;
      inProgressTasks.forEach(async (task) => {
      // remove from store  
      uploadManager.cancelFileUpload(task.id, true);
      // cancel upload tasks  
      await cancelUploadTask(task.id, true);
      }
      );
     // cancel the revision as well
     await cancelRevision(projectApiClient, registrationRevisionId);
    }
  },[createDialog, cancelRevision, cancelUploadTask, inProgressTasks, projectApiClient, uploadManager]);
  
  const cancelImportBtn = (
    <FaroButton onClick={onCancelAllUploadTasksClick}
      isDisabled={isDisabled} 
      sx={{ textTransform: "none",
            minWidth: "89px",
            height: FARO_BUTTON_HEIGHT,
            py: "6px",
            px: "16px",
            color: sphereColors.blue500,
            backgroundColor: sphereColors.gray50,
            border: "0px",
            fontWeight: "600",
            "&:hover": {
              backgroundColor: addTransparency({
                color: sphereColors.gray50,
                alpha: EDecimalToHex.thirtyEight,
              }),
            },
            "&:active": {
              backgroundColor: addTransparency({
                color: sphereColors.gray500,
                alpha: EDecimalToHex.sixtyFour,
              }),
              },
            "&.Mui-disabled": {
            // Since there is no opacity on disabled, add transparency to the background color.
            border: addTransparency({
              color: sphereColors.gray50,
              alpha: EDecimalToHex.sixteen,
            })},

        }}
      loadingTrackColor="sphereColors.gray500">
        <Box data-testid="sa-upload-data-button">
          Cancel Import
        </Box>  
  </FaroButton>
  );

  return (
      <SphereTooltip
        dataTestId="sa-upload-data-tooltip"
        boxProps={{
          sx: {
            display: "inline-block",
          },
        }}
        title={isDisabled ? "The import cannot be cancelled at the current step" : "Cancel the current import process"}
      >
      {cancelImportBtn}
      </SphereTooltip>
    );

}