import { useDialog } from "@components/common/dialog/dialog-provider";
import { SPACE_ELEMENTS_OF_MODAL } from "@components/common/dialog/faro-dialog";
import { useFileUploadContext } from "@context-providers/file-upload/file-uploads-context";
import { GUID } from "@faro-lotv/ielement-types";
import { Typography } from "@mui/material";
import { useCallback } from "react";

/**
 * Type of the callback returned by the hook
 *
 * @param id The id of the upload task to cancel
 * @param shouldSkipPromptAndRemoveFileFromStore Boolean to skip the prompt 
 * while aborting uploads and removing the file from store
 */
export type CancelUploadFn = (id: GUID, shouldSkipPromptAndRemoveFileFromStore: boolean ) => Promise<boolean>;

/**
 * @returns This hook returns a function to cancel a given file upload task.
 *
 */
export function useCancelUpload(): CancelUploadFn {
  const { uploadManager } = useFileUploadContext();

  const { createDialog } = useDialog();

  const cancelFn = useCallback(
    async (id: GUID, shouldSkipPromptAndRemoveFileFromStore: boolean = false) => {
      // Default Use Case : prompt user if not skipped
      if (!shouldSkipPromptAndRemoveFileFromStore) {
        const hasAccepted = await createDialog(
          {
            title: "Cancel Upload?",
            confirmText: "Confirm",
            closeText: "Cancel",
          },
          <Typography
            sx={{
              fontSize: "14px",
              marginTop: "0px",
              marginBottom: SPACE_ELEMENTS_OF_MODAL,
            }}
          >
            Are you sure you want to cancel the upload?
            <br />
            You have to select the file again if you want to re-upload.
          </Typography>
        );
        if (hasAccepted) {
          return uploadManager.cancelFileUpload(id, shouldSkipPromptAndRemoveFileFromStore);
        }
      } else {
        // cancel all file uploads without any prompts 
        return uploadManager.cancelFileUpload(id, shouldSkipPromptAndRemoveFileFromStore);
      }
       return false;
    },
    [createDialog, uploadManager]
  );
  return cancelFn;
}

